import { CookieHelper } from './CookieHelper'
import {
  ROLE_SUPER_ADMIN_ID,
  ROLE_SALES_ID,
  ROLE_ADMIN_ID,
  ROLE_EXHIBITOR_ADMIN_ID,
  ROLE_MODERATOR_ID,
  ROLE_OPERATOR_ID,
  ROLE_PRESENTER_ID,
  ROLE_EXHIBITOR_REP_ID,
  ROLE_ATTENDEE_ID
} from './RoleHelper';
import serverAPI from '../serverAPI'

class SecurityHelper {
  constructor() {
    this.user = null

    const account = CookieHelper.Get("admin.account")
    if (!account) this.adminAccount = null
    else {
      try {
        this.adminAccount = JSON.parse(account)
      }
      catch (e) {
        console.error(e)
        this.adminAccount = null
      }
    }
  }

  async loadUser(forceReload) {
    if (this.user && !forceReload) {
      return new Promise(r => r(this.user))
    }
    const profileData = await serverAPI("my.profile", "get", { photosize: 200, showdefaultphoto: false })
    this.user = profileData.results[0]
    const rightsData = await serverAPI("my.rights")
    this.rights = {
      actions: rightsData.results,
      conditionalPolicies: rightsData.ConditionedPolicies || []
    }
    try {
      this.settings = await serverAPI("conference.getsettings", "get")
    }
    catch (err) {
      this.settings = {}
      console.error(err)
    }
    return {
      user: this.user,
      rights: this.rights
    }
  }

  getUser() {
    return this.user
  }

  getSettings() {
    return this.settings
  }

  clearUser() {
    this.user = null
    this.rights = null
    this.adminAccount = null
    this.settings = null
    CookieHelper.Remove("admin.account")
  }

  isAccountSelected() {
    if (!this.canExecuteAction("account.change")) return true
    return this.isAdminAccountSelected();
  }

  isAdminAccountSelected() {
    if (this.adminAccount) return true
    return false
  }

  getAdminAccount() {
    return this.adminAccount
  }

  selectAdminAccount(account) {
    this.adminAccount = account
    CookieHelper.Set("admin.account", JSON.stringify(account))
  }

  canExecuteAction(action) {
    return this.rights.actions.indexOf(action) >= 0
  }

  canExecuteAnyAction(actions) {
    return actions.find(a => this.canExecuteAction(a)) != null
  }

  canExecuteActionOnResource(action, resource) {
    var allow = false
    var conditionsOnAction = 0

    for (var p = 0; p < this.rights.conditionalPolicies.length; p++) {
      const policy = this.rights.conditionalPolicies[p]
      if (policy.Action !== action) continue

      conditionsOnAction++

      for (var c = 0; c < policy.Conditions.length; c++) {
        const condition = policy.Conditions[c]
        if (condition.Type === "Pattern") {
          const matches = new RegExp("^" + condition.Condition.split("*").join(".*") + "$", "i").test(resource[condition.Property])
          // Allow => effect=0,  Deny => effect=1
          if (matches) allow = (!condition.Effect)
        }
        else
          console.error("Condition type " + condition.Type + " not supported.")
      }
    }

    // If there are no conditions on this action, just see if the user can execute it or not
    if (conditionsOnAction === 0) return this.canExecuteAction(action)
    return allow
  }

  hasAnyRole(roleIds) {
    return roleIds.some(id => this.hasRole(id))
  }

  hasRole(id) {
    id = id.toLowerCase();
    return this.user.Roles.some(role => role.Id.toLowerCase() === id);
  }

  isAdministrator() {
    return this.hasRole(ROLE_ADMIN_ID) || this.hasRole(ROLE_SUPER_ADMIN_ID) || this.hasRole(ROLE_SALES_ID);
  }

  isAttendee() {
    return this.hasRole(ROLE_ATTENDEE_ID);
  }

  isPresenter() {
    return this.hasRole(ROLE_PRESENTER_ID);
  }

  isExhibitorRep() {
    return this.hasRole(ROLE_EXHIBITOR_REP_ID);
  }

  isExhibitorAdmin() {
    return this.hasRole(ROLE_EXHIBITOR_ADMIN_ID);
  }

  isOperator() {
    return this.hasRole(ROLE_OPERATOR_ID);
  }

  isModerator() {
    return this.hasRole(ROLE_MODERATOR_ID);
  }

  isAttendeeOnly() {
    return this.hasRole(ROLE_ATTENDEE_ID) &&
      !this.isAdministrator() &&
      !this.isPresenter() &&
      !this.isExhibitorAdmin() && !this.isExhibitorRep() &&
      !this.isOperator() &&
      !this.isModerator();
  }

  isExhibitor(){
    return this.isExhibitorAdmin() || this.isExhibitorRep()
  }

  isExhibitorOnly() {
    return this.isExhibitor() &&
      !this.isAdministrator() &&
      !this.isPresenter() &&
      !this.isAttendee() &&
      !this.isOperator() &&
      !this.isModerator();
  }

  isExhibitorRepOnly() {
    return this.isExhibitorRep() &&
      !this.isExhibitorAdmin() &&
      !this.isAdministrator() &&
      !this.isPresenter() &&
      !this.isAttendee() &&
      !this.isOperator() &&
      !this.isModerator();
  }

  isAdministratorOnly() {
    return this.isAdministrator() &&
      !this.isExhibitor() &&
      !this.isPresenter() &&
      !this.isAttendee() &&
      !this.isOperator() &&
      !this.isModerator();
  }

  isMe = (user) => {
    return this.user.Id === user.Id
  }

  isEngineericaStaff(){
    return this.hasRole(ROLE_SUPER_ADMIN_ID) || this.hasRole(ROLE_SALES_ID)
  }
}

const securityHelper = new SecurityHelper()
export default securityHelper
