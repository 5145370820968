import React, { Component } from 'react';

import { Card, CardBody, CardFooter } from 'reactstrap';

import { getColorByString } from '../../../helpers/ColorHelper';

class PresentationLinkWidget extends Component {

  getColor(){
    if (this.props.color){
      return this.props.color
    }
    return getColorByString(this.props.title, '600');
  }

  isPlainColor = () => {
    return this.getColor().startsWith('#');
  }

  onClick = (e) => {
    e.preventDefault();
    this.props.onLinkClick(e);
  }

  renderIcon = () => {
    var style = {};

    var className = `${this.props.icon} p-3 text-white font-2xl mr-3 float-left rounded`;

    if (this.isPlainColor()){
      style.backgroundColor = this.getColor();
    } else {
      className += ` bg-${this.getColor()}`
    }

    return (
      <i className={className} style={style}/>
    );
  }

  render(){
    return (
      <Card onClick={this.onClick} style={{cursor: "pointer"}}>
        <CardBody className="clearfix p-3">
          {this.renderIcon()}
          <div className={`h5 mb-0 mt-2 ${this.props.truncateTitle ? "text-truncate overflow-hidden" : ""}`}>{this.props.title}</div>
          <div className="text-muted text-uppercase font-weight-bold mt-1" style={{fontSize: '.65rem'}}>{this.props.subtitle}</div>
        </CardBody>
        <CardFooter className="px-3 py-2">
          <a href="/#" className="font-weight-bold font-xs btn-block text-muted" onClick={this.onClick}>{this.props.linkText}
            <i className="fas fa-angle-right float-right font-lg"></i></a>
        </CardFooter>
      </Card>
    );
  }
}

PresentationLinkWidget.defaultProps = {
  linkText: "View Results",
  truncateTitle: true
}


export default PresentationLinkWidget;